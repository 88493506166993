<template>
  <div class="home">
    <HelloWorld msg="Welcome to the Fabricare Customer Gateway" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>
